import React from "react";
import { graphql } from "gatsby";
import get from "lodash/get";
import { Helmet } from "react-helmet";
import Layout from "../components/layout";
import ArticlePreview from "../components/article-preview";
import Container from "@material-ui/core/Container";

class AboutIndex extends React.Component {
  render() {
    const siteTitle = get(this, "props.data.site.siteMetadata.title");
    const posts = get(this, "props.data.allContentfulBlogPost.edges");

    return (
      <Layout location={this.props.location}>
        <div style={{ background: "#fff" }}>
          <Helmet title={siteTitle} />
          <div className="wrapper">
            <div className="row">
              <div className="col-1"></div>
              <div className="col-2">
                <h2>About</h2>
                <p>
                  Music and calming sounds can cloak frightening noises like
                  fireworks and thunder, or bothersome sounds like a apartment
                  neighbors or construction. It can even be helpful for
                  separation anxiety or "pep up" a lethargic pet. The cadence of
                  certain sounds influences the body’s natural rhythms and can
                  speed them up and energize the listener, or slow them down to
                  calm them.
                </p>
                <p>
                  I created The DogZenGarden after noticing the effective relief
                  calming music and audio had on my dog, Boone, who was
                  suffering greatly during thunderstorms or periods of
                  separation. I wanted a tool to provide dog owners with a
                  variety of calming music and sounds. Allowing the pet owner to
                  blend together the perfect mix of music and sounds to produces
                  the most efficient mix for your dog.
                </p>
              </div>
            </div>
            <Container>
              <h2 className="section-headline">Recent articles</h2>
              <ul className="article-list">
                {posts.map(({ node }) => {
                  return (
                    <li key={node.slug}>
                      <ArticlePreview article={node} />
                    </li>
                  );
                })}
              </ul>
            </Container>
          </div>
        </div>
      </Layout>
    );
  }
}

export const pageQuery = graphql`
  query AboutIndexQuery {
    allContentfulBlogPost(sort: { fields: [publishDate], order: DESC }) {
      edges {
        node {
          title
          slug
          publishDate(formatString: "MMMM Do, YYYY")
          tags
          heroImage {
            fluid(maxWidth: 350, maxHeight: 196, resizingBehavior: SCALE) {
              ...GatsbyContentfulFluid_tracedSVG
            }
            file {
              url
              fileName
              contentType
            }
          }
          description {
            childMarkdownRemark {
              html
            }
          }
        }
      }
    }
  }
`;

export default AboutIndex;
